$(function() {
  // 画像を管理するための配列を定義する。
  var files_array = [];

  function readURL(input, $postFileContainer) {
    var file_length = input.files.length;

    if (input.files && input.files[0]) {

      for(let i=0; i<file_length; i++){
        let file = input.files[i];
        files_array.push(file);

        if(file.type === "application/pdf" ||
           file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
           file.type === "application/ms-excel") {
          var filename = file.name; // ファイル名を取得
          var fileInfo = '<li><p>ファイル名: ' + filename + '</p></li>'; // 表示するHTML要素を作成
          $postFileContainer.append(fileInfo); // HTML要素をページに追加
        } else if(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          var reader = new FileReader();

          reader.onload = function (e) {
            var src = e.target.result;

            if(5242880 <= e.total) {
              var img = '<li><p class="is-error">受付可能サイズは10MB以下です</p><img src="'+ src +'"></li>';
              //var img = '<li><p class="is-error">受付可能サイズは5MB以下です</p><img src="'+ src +'"><a class="jsc-prevDlt"></a></li>';
              $postFileContainer.append(img);
              //$('.jsc-prevDlt').addClass('c-form-field-imageDlt');
            } else {
              var img = '<li><img src="'+ src +'"></li>';
              //var img = '<li><img src="'+ src +'"><a class="jsc-prevDlt"></a></li>';
              $postFileContainer.append(img);
              //$('.jsc-prevDlt').addClass('c-form-field-imageDlt');
            }
          }
          reader.readAsDataURL(input.files[i]);
        }
      }
      //console.log(files_array);
    }
  }

  $(".post_files").on('change', function(){
    var $postFileContainer = $(this).closest('.p-entriesNew-form-field-img-label').find('.jsc-postFile');
    $postFileContainer.empty();
    readURL(this, $postFileContainer);
  });

  $(".post_img").on('change', function(){
    var $postFileContainer = $(this).closest('.p-usersettingsNew-form-field-img-label').find('.jsc-postFile');
    $postFileContainer.empty();
    readURL(this, $postFileContainer);
  });

  //$(".jsc-postImg").on('click', '.jsc-prevDlt', function(e){
  //  var imgIndex = $(this).closest('li').index();

  //  $(".jsc-postImg").find('li').eq(imgIndex).remove();

  //  files_array.splice(imgIndex, 1);

  //  $("#post_img").trigger('change');

  //  console.log(files_array);

  //  e.preventDefault();
  //});
});

import "jquery";
global.$ = require('jquery');

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import luminous from '../src/js/luminous.js';
import datetimepicker from '../src/js/datepicker.js';
import ImageUploaderEntry from '../src/js/imagesUploader_entry.js';
// import Dropzone from '../src/js/Dropzone.js';

luminous();

import '../src/scss/application';
require.context('../src/images', true, /\.(png|jpg|jpeg|svg)$/);
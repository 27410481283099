import $ from "jquery";
import moment from 'moment';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-ja'; //datepickerは日本語かする場合にこれが必要

$(function(){
  var obj = $("#hogehoge");
  var timeStr = moment().format('YYYY-MM-DD');
  $('.datepicker').datepicker({
    minDate: 2, // 現在日から2日後から選択可能
    beforeShowDay: function(date) {
      var day = date.getDay();
      return [(day != 0 && day != 6), ""]; // 土日を選択不可にする
    }
  });
});

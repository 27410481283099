import { LuminousGallery } from 'luminous-lightbox'
import 'luminous-lightbox/dist/luminous-basic.css'



const luminous = window.onload = () => {
  const trigger = document.querySelectorAll('.luminous')

  new LuminousGallery(trigger)
}

export default luminous